var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"elevation":"0","block":"","height":"30px"},domProps:{"textContent":_vm._s(_vm.$t('edit', { name: '' }))},on:{"click":_vm.fetchDiscountsSubService}},on))]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$tc("discount", 2))+" ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"10","md":"2"}},[_c('v-text-field',{staticClass:"ml-auto filters search",attrs:{"outlined":"","dense":"","prepend-inner-icon":"mdi-magnify","label":_vm.$t('search'),"hide-details":"","single-line":""},on:{"input":_vm.searchFilter},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"2","order":_vm.$vuetify.breakpoint.xs ? 1 : null}},[_c('v-select',{staticClass:"ml-auto filters",attrs:{"label":_vm.$t('appointments.state'),"outlined":"","dense":"","hide-details":"","single-line":"","items":_vm.states,"clearable":""},on:{"input":_vm.searchFilter},model:{value:(_vm.filters.state),callback:function ($$v) {_vm.$set(_vm.filters, "state", $$v)},expression:"filters.state"}})],1),_c('v-spacer'),_c('NewDiscount',{attrs:{"subservice":_vm.subservice},on:{"update":_vm.fetchDiscountsSubService}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n( item.type == "percent" ? item.discount / 100 : item.discount, item.type ))+" ")]}},{key:"item.max_redemptions",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.max_redemptions || "-")+" ")]}},{key:"item.redeem_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.redeem_by ? _vm.$d(new Date(item.redeem_by), "date2digits") : "-")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.update(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$delete")])],1):_vm._e()]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticStyle:{"color":"black"},attrs:{"color":item.active ? 'primary' : 'gris1'}},[_vm._v(" "+_vm._s(item.active ? _vm.$t("discounts.states.active") : _vm.$t("discounts.states.inactive"))+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }