<template>
  <v-dialog v-model="dialog" width="1000px">
    <template v-slot:activator="{ on }">
      <v-btn
        elevation="0"
        v-on="on"
        @click="fetchDiscountsSubService"
        block
        height="30px"
        v-text="$t('edit', { name: '' })"
      />
    </template>
    <v-card>
      <v-card-title>
        {{ $tc("discount", 2) }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row align="center">
          <v-col cols="10" md="2" class="py-0">
            <v-text-field
              outlined
              dense
              v-model="filters.search"
              prepend-inner-icon="mdi-magnify"
              class="ml-auto filters search"
              :label="$t('search')"
              hide-details
              single-line
              @input="searchFilter"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
            :order="$vuetify.breakpoint.xs ? 1 : null"
            class="py-0"
          >
            <v-select
              :label="$t('appointments.state')"
              outlined
              dense
              v-model="filters.state"
              class="ml-auto filters"
              hide-details
              single-line
              :items="states"
              clearable
              @input="searchFilter"
            />
          </v-col>
          <v-spacer></v-spacer>
          <NewDiscount
            :subservice="subservice"
            @update="fetchDiscountsSubService"
          />
        </v-row>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
      >
        <template v-slot:item.discount="{ item }">
          {{
            $n(
              item.type == "percent" ? item.discount / 100 : item.discount,
              item.type
            )
          }}
        </template>
        <template v-slot:item.max_redemptions="{ item }">
          {{ item.max_redemptions || "-" }}
        </template>
        <template v-slot:item.redeem_by="{ item }">
          {{
            item.redeem_by ? $d(new Date(item.redeem_by), "date2digits") : "-"
          }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon @click="update(item)" v-if="item.active">
            <v-icon small>$delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.active="{ item }">
          <v-chip
            :color="item.active ? 'primary' : 'gris1'"
            style="color: black"
          >
            {{
              item.active
                ? $t("discounts.states.active")
                : $t("discounts.states.inactive")
            }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { debounce } from "lodash";

export default {
  name: "DiscountsSubServiceList",
  props: ["subservice"],
  components: {
    NewDiscount: () =>
      import(
        "@/components/user/settings/configuracion/subservices/configuration/NewDiscount"
      ),
  },
  watch: {
    options: {
      handler() {
        this.fetchDiscountsSubService();
      },
      deep: true,
    },
  },
  data() {
    return {
      dialog: false,
      items: [],
      total: null,
      options: {},
      filters: {},
      menu: false,

      states: [
        { text: this.$t("all"), value: "all" },
        { text: this.$t("discounts.states.active"), value: "active" },
        { text: this.$t("discounts.states.inactive"), value: "inactive" },
      ],

      headers: [
        {
          text: this.$t("discounts.code"),
          align: "center",
          sortable: false,
          value: "code",
        },
        {
          text: this.$tc("discount"),
          align: "center",
          sortable: false,
          value: "discount",
        },
        {
          text: this.$t("discounts.redeem_by"),
          align: "center",
          sortable: false,
          value: "redeem_by",
        },
        {
          text: this.$t("discounts.max_redemptions"),
          align: "center",
          sortable: false,
          value: "max_redemptions",
        },

        {
          text: this.$t("discounts.active"),
          align: "center",
          sortable: false,
          value: "active",
        },

        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  mounted() {
    this.fetchDiscountsSubService();
  },
  methods: {
    ...mapActions("discounts", ["getDiscounts", "updateDiscount"]),
    fetchDiscountsSubService() {
      this.getDiscounts({
        filters: {
          sub_artist_type_id: this.subservice.id,
          search: this.filters.search,
          state: this.filters.state,
        },
        paginate: this.options,
      }).then((response) => {
        console.log(this.filters);
        this.items = response.data;
        this.total = response.total;
      });
    },
    update(item) {
      this.$confirm(this.$t("discounts.deactivate", { code: item.code })).then(
        () => {
          this.updateDiscount(item.id).then(() =>
            this.fetchDiscountsSubService()
          );
        }
      );
    },
    searchFilter: debounce(function(val) {
      this.fetchDiscountsSubService();
    }, 500),
  },
};
</script>

<style></style>
